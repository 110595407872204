import React, { useEffect, useState } from "react";
import { RiDeleteBin6Fill } from "react-icons/ri";
import { CirclesWithBar } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import styles from "../assets/scss/UserDetails.module.scss";
import axios from "../components/hooks/axios";
import { emptySong, fetchAlbumSongs } from "../redux/slices/albumSlice";
import { toast } from "react-toastify";

const AlbumPopup = ({ albumIndex, setAlbumShow }) => {
	const dispatch = useDispatch();
    const [offset, setOffset] = useState(0);
    const [reload, setReload] = useState(0)
	const { albumDetails, songDetails, isSongLoading } = useSelector((state) => state.album);

	useEffect(() => {
		dispatch(fetchAlbumSongs({ albumIndex, offset }));
	}, [offset, reload]);

    const deleteSongs = async (id) => {
        const { data } = await axios.delete(`album/deleteSong/${id}`);
        if (data.status) {
            dispatch(emptySong());
            setOffset(0);
            toast.success("Song Successfully Deleted");
            setReload(Math.random());
        }
	};

	function ReachBottom(e) {
		const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
		if (bottom) {
			setOffset((pre) => pre + 10);
		}
	}

	return (
		<div className={styles.AlbumPopupMainContainer} onClick={() => setAlbumShow(false)}>
			<div className={`chat-popup MainBodyContainer ${styles.AlbumPopupContainer}`} onClick={(e) => e.stopPropagation()}>
				<button className="close-popup" onClick={() => setAlbumShow(false)}>
					X
				</button>

				<div className={styles.AlbumPopup}>
					<div className={styles.AlbumDetails}>
						{isSongLoading ? (
							<CirclesWithBar
								height="100"
								width="100"
								color="#DD3333"
								wrapperStyle={{
									width: "100%",
									height: "20%",
									displayL: "flex",
									alignItems: "center",
									justifyContent: "center",
								}}
								wrapperClass=""
								visible={true}
								outerCircleColor=""
								innerCircleColor=""
								barColor=""
								ariaLabel="circles-with-bar-loading"
							/>
						) : (
							<>
								<img src={albumDetails.cover_photo} alt="" />
								<div className={styles.AlbumContent}>
									<div className={styles.AlbumContentInner}>
										<h2>{albumDetails.title}</h2>
										<p>{albumDetails.description}</p>
									</div>
								</div>
							</>
						)}
					</div>
					<div className={styles.AlbumSongDetails} onScroll={ReachBottom}>
						{songDetails.map((data, index) => (
							<div key={index} className={styles.songBox}>
								<div className={styles.Left}>
									<img src={data.thumbnail} alt="" />
									<h4>{data.title}</h4>
								</div>
								<div className={styles.Right}>
									<RiDeleteBin6Fill className={styles.Icon} onClick={() => deleteSongs(data._id)} />
								</div>
							</div>
						))}
					</div>
				</div>
			</div>
		</div>
	);
};

export default AlbumPopup;
