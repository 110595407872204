import { AiOutlineUserAdd } from "react-icons/ai";
import { FaIdCard, FaUserAlt } from "react-icons/fa";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import img1 from "../assets/Images/Vector (1).png";
import Header from "../components/Header";

function Dashboard() {
	const { userType } = useSelector((state) => state.user);
	const {
		isLoading,
		allUsersDetails: { count },
	} = useSelector((state) => state.action);
	const { isLoading: orderLoading, orderCount } = useSelector((state) => state.order);

	return (
		<div className="display-flex Dashboard">
			<Header />
			<div className="MainBodyContainer">
				<div className="display-flex" style={{ justifyContent: "space-between" }}>
					<h4>Dashboard</h4>
					{userType === "superAdmin" && (
						<Link to="/Register" className="AddUserBtn">
							<AiOutlineUserAdd /> Add User
						</Link>
					)}
				</div>

				<div className="DashboardNav">
					<Link className="blue-bg" to="/AllUser">
						<FaUserAlt /> &nbsp; Total Users {isLoading ? "Loading.." : count}
					</Link>
					<Link className="red-bg" to="/Orders">
						<FaIdCard /> &nbsp; Orders
					</Link>
					<Link className="green-bg">
						<img src={img1} alt="" /> &nbsp; &nbsp; 25,356,23
					</Link>
				</div>

				<br />
				<h5>Top 10 Creators</h5>
				<div className="column-boxes">
					<div className="column-box"></div>
					<div className="column-box"></div>
					<div className="column-box"></div>
					<div className="column-box"></div>
					<div className="column-box"></div>
					<div className="column-box"></div>
					<div className="column-box"></div>
					<div className="column-box"></div>
					<div className="column-box"></div>
					<div className="column-box"></div>
				</div>
			</div>
		</div>
	);
}
export default Dashboard;
