import React, { useEffect, useState } from "react";
import { CgPlayPauseO } from "react-icons/cg";
import { FaFileDownload } from "react-icons/fa";
import { RiDeleteBin6Fill } from "react-icons/ri";
import { VscPlayCircle } from "react-icons/vsc";
import { CirclesWithBar } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import styles from "../assets/scss/UserDetails.module.scss";
import { deleteLecture, deleteReview, fetchUserCreateClasses } from "../redux/slices/actionSlice";

const ClassPopup = ({ setClassShow, classIndex, id }) => {
	const [videoLink, setVideoLink] = useState("");
	const [activeClassId, setActiveClassId] = useState("");
	const { token } = useSelector((state) => state.user);
	const dispatch = useDispatch();
	const classData = useSelector((state) => state.action.userClassData.classes[classIndex]);
	const { isLectureDeleted, isReviewDeleted } = useSelector((state) => state.action.userClassData);
	const { isLoading } = useSelector((state) => state.action);

	const handelDelete = (id) => {
		dispatch(deleteLecture(id));
	};
	const handelReviewDelete = (id) => {
		dispatch(deleteReview({ id, classId: classData._id}));
	};
	
	useEffect(() => {
		dispatch(fetchUserCreateClasses({ id, token }));
	}, [isLectureDeleted, isReviewDeleted]);

	return (
		<div className={`chat-popup MainBodyContainer ${styles.ClassPopupContainer}`}>
			<button className="close-popup" onClick={() => setClassShow(false)}>
				X
			</button>

			<div className={styles.classPopup}>
				<div className={styles.classBox}>
					<div className={styles.column1}>
						<div className={styles.mediaContainer}>
							{videoLink === "" ? (
								<img src={classData.coverpic} alt="" />
							) : (
								<video controls autoPlay src={videoLink}></video>
							)}
						</div>
						<div className={styles.TextContainer}>
							<h3>{classData.courseTitle}</h3>
							<p>{classData.courseDescription}</p>
						</div>
					</div>

					<div className={styles.column2}>
						<div className={styles.LectureContainer}>
							<h4>Lectures</h4>
							<p>{classData.media.length == 0 && "No Lectures"}</p>
							{isLoading ? (
								<CirclesWithBar
									height="100"
									width="100"
									color="#DD3333"
									wrapperStyle={{
										width: "100%",
										height: "90%",
										displayL: "flex",
										alignItems: "center",
										justifyContent: "center",
									}}
									wrapperClass=""
									visible={true}
									outerCircleColor=""
									innerCircleColor=""
									barColor=""
									ariaLabel="circles-with-bar-loading"
								/>
							) : (
								classData.media.map((data, index) => (
									<div key={index} className={styles.LectureBox}>
										<div className={styles.text}>
											<p>{index + 1}</p>
											<h6>{data.lectureTitle}</h6>
										</div>
										<div className={styles.Button}>
											<button
												onClick={() => {
													if (videoLink === "") {
														setActiveClassId(data._id);
														setVideoLink(data.videoUpload);
													} else {
														setActiveClassId("");
														setVideoLink("");
													}
												}}>
												{data._id === activeClassId && videoLink != "" ? (
													<CgPlayPauseO className={styles.Icon} />
												) : (
													<VscPlayCircle className={styles.Icon} />
												)}
											</button>
											<button onClick={() => (window.location.href = data.documentUpload)}>
												<FaFileDownload className={styles.Icon} />
											</button>
											<button onClick={() => handelDelete(data._id)}>
												<RiDeleteBin6Fill className={styles.Icon} />
											</button>
										</div>
									</div>
								))
							)}
						</div>

						<div className={styles.ReviewContainer}>
							<h4>Reviews</h4>
							<p>{classData.review.length == 0 && "No reviews"}</p>
							{isLoading ? (
								<CirclesWithBar
									height="100"
									width="100"
									color="#DD3333"
									wrapperStyle={{
										width: "100%",
										height: "90%",
										displayL: "flex",
										alignItems: "center",
										justifyContent: "center",
									}}
									wrapperClass=""
									visible={true}
									outerCircleColor=""
									innerCircleColor=""
									barColor=""
									ariaLabel="circles-with-bar-loading"
								/>
							) : (
								classData.review.map((data, index) => (
									<div key={index} className={styles.CommentBox}>
										<div className={styles.text}>
											<p>{index + 1}</p>
											<span>
												<img src={data.created_by?.pic} alt="" />
												<p>{data.created_by?.full_name}</p>
											</span>
											<div>{data.reviewText}</div>
										</div>
										<div className={styles.Button}>
											<button onClick={() => handelReviewDelete(data._id)}>
												<RiDeleteBin6Fill className={styles.Icon} />
											</button>
										</div>
									</div>
								))
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ClassPopup;
