import React, { useState } from "react";
import axios from "./hooks/axios";
import styles from "../assets/scss/ReportList.module.scss";


const ReportListPopup = ({ setReportListShow, reportDetail, reportType, reportID, setReload }) => {
	const [totalPost, setTotalPost] = useState("");

	// useEffect(() => {
	// 	axios
	// 		.get(``)
	// 		.then(({ data }) => {})
	// 		.catch((e) => console.log(e));
	// }, []);

	const handelAction = () => {
		axios
			.put(`admin/change-report-status/${reportID}/${reportType}`)
			.then(({ data }) => {
				if (data.status === true) {
					console.log(data.data);
					setReload(Math.random());
					setReportListShow(false);
				}
			})
			.catch((e) => console.log(e));
	};

	const ImagePost = ({ post }) => {
		return (
			<div className="PostMainContainer">
				<div className="imageBox">
					<img src={post.media[0].file} alt="" />
				</div>
				<h6>{post.captions}</h6>
				<div className="contentBox">
					<p>likes = {post.likes_count}</p>
					<p>comments = {post.comment_counts}</p>
				</div>
			</div>
		);
	};
	const VideoPost = ({ post }) => {
		return (
			<div className="PostMainContainer">
				<div className="VideoBox">
					<video controls autoPlay={false}>
						<source src={post.media[0].file} />
					</video>
				</div>
				<h6>{post.captions}</h6>
				<div className="contentBox">
					<p>likes = {post.likes_count}</p>
					<p>comments = {post.comment_counts}</p>
				</div>
			</div>
		);
	};
	const AudioPost = ({ post }) => {
		return (
			<div className="PostMainContainer">
				<div className="AudioBox">
					<div className="waves">
						<div className="music-wave"></div>
						<div className="music-wave"></div>
						<div className="music-wave"></div>
						<div className="music-wave"></div>
						<div className="music-wave"></div>
						<div className="music-wave"></div>
						<div className="music-wave"></div>
						<div className="music-wave"></div>
						<div className="music-wave"></div>
						<div className="music-wave"></div>
					</div>
					<audio autoPlay={false} src={post.media[0].file} controls></audio>
				</div>
				<h6>{post.captions}</h6>
				<div className="contentBox">
					<p>likes = {post.likes_count}</p>
					<p>comments = {post.comment_counts}</p>
				</div>
			</div>
		);
	};
	const CaptionPost = ({ post }) => {
		return (
			<div className="PostMainContainer">
				<div className="CaptionBox">{post.caption}</div>
				<div className="contentBox">
					<p>likes = {post.likes_count}</p>
					<p>comments = {post.comment_counts}</p>
				</div>
			</div>
		);
	};

	return (
		<div className={`chat-popup MainBodyContainer UserPostsPopup ${styles.UserPostsPopup}`}>
			<div className={styles.UserPostsPopupInner}>
				<button className="close-popup" onClick={() => setReportListShow(false)}>
					X
				</button>
				<h3>Reported {reportType}</h3>

				<div className={styles.UserReportContainer}>
					{reportType === "user" && (
						<>
							<div className="profileImageContainer">
								<img src={reportDetail.pic} alt="" />
							</div>
							<h4>
								Name: <span>{reportDetail.full_name}</span>
							</h4>
							<h4>
								Email: <span>{reportDetail.email}</span>
							</h4>
							<h4>
								Description: <span>{reportDetail.description ? reportDetail.description : "null"}</span>
							</h4>
							<h4>
								User Type: <span>{reportDetail.user_type}</span>
							</h4>
							<h4>
								Gender: <span>{reportDetail.gender ? reportDetail.gender : "null"}</span>
							</h4>
							<h4>
								Mobile Number: <span>{reportDetail.mobile_number ? reportDetail.mobile_number : "null"}</span>
							</h4>
							<h4>
								Block Status: <span>{reportDetail.is_block ? "True" : "False"}</span>
							</h4>
							<h4>
								Report Status: <span>{reportDetail.is_reported ? "True" : "False"}</span>
							</h4>
							<h4>
								Address: <span>{reportDetail.address ? reportDetail.address : "null"}</span>
							</h4>
							<h4>
								City: <span>{reportDetail.city ? reportDetail.city : "null"}</span>
							</h4>
							<h4>
								Pin Code: <span>{reportDetail.pincode ? reportDetail.pincode : "null"}</span>
							</h4>
						</>
					)}
					{reportType === "post" && (
						<>
							{console.log(reportDetail)}
							{reportDetail.media[0] ? (
								<>
									{reportDetail.media[0].type === "image" && <ImagePost post={reportDetail} />}
									{reportDetail.media[0].type === "video" && <VideoPost post={reportDetail} />}
									{reportDetail.media[0].type === "audio" && <AudioPost post={reportDetail} />}
								</>
							) : (
								<CaptionPost post={reportDetail} />
							)}
						</>
					)}

					<button type="button" onClick={() => handelAction()}>
						Action
					</button>
				</div>
			</div>
		</div>
	);
};

export default ReportListPopup;
