import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import AllUser from "../pages/AllUser";
import Dashboard from "../pages/Dashboard";
import Login from "../pages/Login";
import Notification from "../pages/Notification";
import Orders from "../pages/Orders";
import Register from "../pages/Register";
import ReportList from "../pages/ReportList";
import RequestList from "../pages/RequestList";
import Support from "../pages/Support";
import UserDetails from "../pages/UserDetails";
import VisitingCard from "../pages/VisitingCard";
import { fetchAllUser } from "../redux/slices/actionSlice";
import { fetchOrders } from "../redux/slices/orderSlice";
import Bidding from "./Bidding.component";

const Root = () => {
	const { token, isSuccess } = useSelector((state) => state.user);
	const dispatch = useDispatch();

	useEffect(() => {
		if (isSuccess && token) {
			dispatch(fetchAllUser({ token, page: 1, limit: 20 }));
			dispatch(fetchOrders({ token }));
		}
	}, [isSuccess, token]);

	return (
		<>
			<Routes>
				{token ? (
					<>
						<Route path="/" element={<Dashboard />} />
						<Route path="Register" element={<Register />} />
						<Route path="AllUser" element={<AllUser />} />
						<Route path="Orders" element={<Orders />} />
						<Route path="ReportList" element={<ReportList />} />
						<Route path="VisitingCard" element={<VisitingCard />} />
						<Route path="RequestList" element={<RequestList />} />
						<Route path="Notification" element={<Notification />} />
						<Route path="UserDetails/:id" element={<UserDetails />} />
						<Route path="Support" element={<Support />} />
						<Route path="Bidding" element={<Bidding />} />
					</>
				) : (
					<Route path="/" element={<Login />} />
				)}
				{/* <Route path="*" element={<NotFound />} /> */}
			</Routes>
		</>
	);
};

export default Root;
