import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../components/hooks/axios";
import { toast } from "react-toastify";

const initialState = {
	support: [],
	isLoading: false,
	isSuccess: false,
    isError: false,
    activeTicketId:"",
    activeTicketTitle:"",
    activeUser:{},
};

export const getSupport = createAsyncThunk("getSupport", async () => {
    const { data } = await axios.get("support/get");
    return data;
})

const supportSlice = createSlice({
	name: "supportSlice",
	initialState,
	reducers: {
		setActiveTicketId: (state, { payload: { ticketNumber, ticketTitle } }) => {
			state.activeTicketId = ticketNumber;
			state.activeTicketTitle = ticketTitle;
		},
		setActiveUser: (state, { payload }) => {
			state.activeUser = payload;
		},
	},
	extraReducers: {
		[getSupport.pending]: (state) => {
			state.support = [];
			state.isLoading = true;
			state.isSuccess = false;
			state.isError = false;
		},
		[getSupport.fulfilled]: (state, { payload }) => {
			state.isLoading = false;
			if (payload.status) {
				state.isSuccess = true;
				state.support = payload.data;
			} else {
				state.isError = true;
				toast.error("Something went wrong !");
			}
		},
	},
});


export const { setActiveTicketId, setActiveUser } = supportSlice.actions;
export default supportSlice.reducer;
