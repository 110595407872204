import React, { useEffect, useState } from "react";
import { RiDeleteBin6Fill } from "react-icons/ri";
import { CirclesWithBar } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import SA from "sweetalert";
import userDefaultImage from "../assets/Images/userDefaultImage.jpg";
import styles from "../assets/scss/UserDetails.module.scss";
import ClassPopup from "../components/ClassPopup";
import Header from "../components/Header";
import UserPostsPopup from "../components/UserPostsPopup";
import axios from "../components/hooks/axios";
import { deleteClass, fetchAllPosts, fetchOneUser, fetchUserCreateClasses } from "../redux/slices/actionSlice";
import { albumsDelete, fetchUserAlbums } from "../redux/slices/albumSlice";
import AlbumPopup from "../components/AlbumPopup";

const UserDetails = () => {
	const { id } = useParams();
	const navigate = useNavigate();
	const { oneUser, isLoading, isSuccess, postData } = useSelector((state) => state.action);
	const { count, classes, isClassDeleted } = useSelector((state) => state.action.userClassData);
	const { albumCount, userAlbums, isAlbumDeleted, isLoading: albumLoading } = useSelector((state) => state.album);
	const dispatch = useDispatch();
	const { token } = useSelector((state) => state.user);

	useEffect(() => {
		dispatch(fetchOneUser({ id, token }));
		dispatch(fetchUserCreateClasses({ id, token }));
		dispatch(fetchAllPosts({ id, token, offset: 0 }));
		dispatch(fetchUserAlbums({ id, offset: 0 }));
	}, [dispatch, isClassDeleted, isAlbumDeleted]);

	const [Name, setName] = useState(oneUser.full_name);
	const [Email, setEmail] = useState(oneUser.email);
	const [Number, setNumber] = useState(oneUser.mobile_number);
	const [UserType, setUserType] = useState(oneUser.user_type);
	const [editable, setEditable] = useState(true);
	const [deleteBtn, setDeleteBtn] = useState(false);
	const [blockBtn, setBlockBtn] = useState(false);
	const [postShow, setPostShow] = useState(false);
	const [classIndex, setClassIndex] = useState({});
	const [classShow, setClassShow] = useState(false);
	const [albumIndex, setAlbumIndex] = useState("");
	const [albumShow, setAlbumShow] = useState(false);

	const handelUploadEdits = () => {
		setEditable(true);
		var data = {
			_id: oneUser._id,
			full_name: Name,
			email: Email,
			mobile_number: Number,
			user_type: UserType,
		};
		axios
			.post("/admin/editUser", data)
			.then(({ data }) => {
				if (!data.status) return SA("Something went wrong !! Please try again...");
				SA(data.message);
				console.log(data);
			})
			.catch((e) => console.log(e));
	};

	const handelBlockUser = () => {
		setBlockBtn(true);
		let text = "";
		if (oneUser.is_block) text = "unblock";
		else text = "block";
		SA({
			title: `you want to ${text} this user ?`,
			icon: "warning",
			buttons: true,
		}).then((value) => {
			if (value) {
				axios
					.put("/admin/blockUser", { _id: oneUser._id, is_block: !oneUser.is_block })
					.then(({ data }) => {
						if (data.status === true) {
						} else SA(data.message);
						setBlockBtn(false);
					})
					.catch((e) => {
						console.log(e);
						SA(e);
						setBlockBtn(false);
					});
			} else {
				setBlockBtn(false);
			}
		});
	};

	const handelDeleteUser = () => {
		setDeleteBtn(true);
		SA({
			title: "you want to delete this user ?",
			icon: "warning",
			buttons: true,
		}).then((value) => {
			if (value) {
				axios
					.delete("/admin/deleteuserIndividual", { data: { _id: oneUser._id } })
					.then((result) => {
						if (result.status === 200) {
							navigate("/AllUser");
						} else SA(result.data);
						setDeleteBtn(false);
					})
					.catch((e) => {
						console.log(e);
						SA(e);
						setDeleteBtn(false);
					});
			} else {
				setDeleteBtn(false);
			}
		});
	};

	return (
		<div className="display-flex">
			<Header />

			<div className={` ${styles.UserDetails} MainBodyContainer`}>
				{/* User Details */}
				{isLoading ? (
					<CirclesWithBar
						height="100"
						width="100"
						color="#DD3333"
						wrapperStyle={{
							width: "100%",
							height: "20%",
							displayL: "flex",
							alignItems: "center",
							justifyContent: "center",
						}}
						wrapperClass=""
						visible={true}
						outerCircleColor=""
						innerCircleColor=""
						barColor=""
						ariaLabel="circles-with-bar-loading"
					/>
				) : (
					<>
						<div className={styles.UserContainer}>
							<h1>User Details</h1>
							<div className={styles.userDetailEditContainer}>
								<div className={`${styles.InputContainer} ${styles.profileImageContainer}`}>
									<img src={oneUser.pic ? oneUser.pic : userDefaultImage} alt="" />
								</div>

								<div className={`${styles.InputContainer} ${styles.name}`}>
									<p>Name</p>
									<input
										readOnly={editable}
										type="text"
										defaultValue={oneUser.full_name}
										onChange={(e) => setName(e.target.value)}
										style={{ background: editable ? "" : "#21535f" }}
									/>
								</div>

								<div className={`${styles.InputContainer} ${styles.email}`}>
									<p>Email</p>
									<input
										readOnly={editable}
										type="email"
										defaultValue={oneUser.email}
										onChange={(e) => setEmail(e.target.value)}
										style={{ background: editable ? "" : "#21535f" }}
									/>
								</div>

								<div className={`${styles.InputContainer} ${styles.number}`}>
									<p>Contact Number</p>
									<input
										readOnly={editable}
										type="number"
										defaultValue={oneUser.mobile_number}
										placeholder="0000000000"
										onChange={(e) => setNumber(e.target.value)}
										style={{ background: editable ? "" : "#21535f" }}
									/>
								</div>

								<div className={`${styles.InputContainer} ${styles.type}`}>
									<p>User Type</p>
									<select
										style={{ background: editable ? "" : "#21535f" }}
										disabled={editable}
										defaultValue={oneUser.user_type}
										onChange={(e) => setUserType(e.target.value)}>
										<option value="user">User</option>
										<option value="professional">Professional</option>
									</select>
								</div>
							</div>
							<div className={styles.ButtonContainer}>
								<button
									type="button"
									onClick={() => setEditable(!editable)}
									style={{ background: editable ? "" : "#2c3444", width: "150px" }}>
									{editable ? "Edit" : "Cancel"}
								</button>
								{!editable && (
									<button onClick={handelUploadEdits} style={{ background: editable ? "#2c3444" : "" }}>
										{editable ? "" : "Upload Edits"}
									</button>
								)}
								<button
									onClick={handelBlockUser}
									disabled={blockBtn}
									style={{ background: blockBtn ? "#2c3444" : "" }}>
									{oneUser.is_block ? "Unblock User" : "Block User"}
								</button>
								<button
									onClick={handelDeleteUser}
									disabled={deleteBtn}
									style={{ background: deleteBtn ? "#2c3444" : "" }}>
									Delete User
								</button>
							</div>
						</div>

						<div className={styles.postContainer}>
							<h1>Post Details</h1>
							<div className={styles.PostContent}>
								<h5>Total Posts = {postData.postCount}</h5>
								<button onClick={() => setPostShow(true)}>See all Posts</button>
							</div>
						</div>
						{postShow && <UserPostsPopup setPostShow={setPostShow} user={oneUser} />}
					</>
				)}

				{/* Class for Professional */}
				{oneUser.user_type === "professional" && (
					<div className={styles.ClassContainer}>
						<h1>Class Details</h1>
						{isLoading ? (
							<CirclesWithBar
								height="100"
								width="100"
								color="#DD3333"
								wrapperStyle={{
									width: "100%",
									height: "90%",
									displayL: "flex",
									alignItems: "center",
									justifyContent: "center",
								}}
								wrapperClass=""
								visible={true}
								outerCircleColor=""
								innerCircleColor=""
								barColor=""
								ariaLabel="circles-with-bar-loading"
							/>
						) : (
							<div className={styles.ClassDetail}>
								<h5>Total Class = {count}</h5>
								<p>{count == 0 && "No Classes"}</p>

								<div className={styles.ClassBoxContainer}>
									{isSuccess &&
										classes.map((data, index) => (
											<div
												className={styles.ClassBox}
												key={index}
												onClick={() => {
													setClassShow(true);
													setClassIndex(index);
												}}>
												<img src={data.coverpic} alt="" />
												<div className={styles.TextContainer}>
													<h4>{data.courseTitle}</h4>
													<p>{data.courseDescription}</p>
												</div>
												<div
													className={styles.deleteBtn}
													onClick={(e) => {
														e.stopPropagation();
														dispatch(deleteClass(data._id));
													}}>
													<RiDeleteBin6Fill className={styles.Icon} />
												</div>
											</div>
										))}
								</div>
							</div>
						)}
					</div>
				)}
				{classShow && <ClassPopup setClassShow={setClassShow} classIndex={classIndex} id={id} />}

				{/* Album for User */}

				{oneUser.user_type === "user" && (
					<div className={styles.AlbumContainer}>
						<h1>Album Details</h1>
						{albumLoading ? (
							<CirclesWithBar
								height="100"
								width="100"
								color="#DD3333"
								wrapperStyle={{
									width: "100%",
									height: "90%",
									displayL: "flex",
									alignItems: "center",
									justifyContent: "center",
								}}
								wrapperClass=""
								visible={true}
								outerCircleColor=""
								innerCircleColor=""
								barColor=""
								ariaLabel="circles-with-bar-loading"
							/>
						) : (
							<div className={styles.AlbumDetail}>
								<p>{albumCount === 0 && "No Albums"}</p>

								<div className={styles.AlbumBoxContainer}>
									{userAlbums.map((data, index) => (
										<div
											className={styles.AlbumBox}
											key={index}
											onClick={() => {
												setAlbumIndex(data._id);
												setAlbumShow(true);
											}}>
											<img src={data.cover_photo} alt="" />
											<div className={styles.TextContainer}>
												<h4>{data.title}</h4>
												<p>{data.description}</p>
											</div>
											<div
												className={styles.deleteBtn}
												onClick={(e) => {
													e.stopPropagation();
													dispatch(albumsDelete(data._id));
												}}>
												<RiDeleteBin6Fill className={styles.Icon} />
											</div>
										</div>
									))}
								</div>
							</div>
						)}
					</div>
				)}
				{albumShow && <AlbumPopup albumIndex={albumIndex} setAlbumShow={setAlbumShow} />}
			</div>
		</div>
	);
};

export default UserDetails;
