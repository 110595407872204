import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./slices/userSlice";
import actionSlice from "./slices/actionSlice";
import orderSlice from "./slices/orderSlice";
import albumSlice from "./slices/albumSlice";
import supportSlice from "./slices/supportSlice";

const store = configureStore({
	reducer: {
		user: authSlice,
		action: actionSlice,
		order: orderSlice,
		album: albumSlice,
		support: supportSlice
	},
});

export default store;
