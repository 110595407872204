import { BiSupport } from "react-icons/bi";
import { FaClipboardList, FaShoppingCart, FaTachometerAlt, FaUserAlt } from "react-icons/fa";
import { GoReport } from "react-icons/go";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import img5 from "../assets/Images/Vector (5).png";
import styles from "../assets/scss/Header.module.scss";
import { logout } from "../redux/slices/userSlice";

function Header() {
	const dispatch = useDispatch();
	const { allUser } = useSelector((state) => state.action.allUsersDetails);
	const { pending } = useSelector((state) => state.order);

	return (
		<div className={styles.nav}>
			{/* <div>
				<img src={JSON.parse(localStorage.getItem("admin_data")).pic} className={styles.profile-img-side-bar} alt="" />
			</div> */}
			<div>
				<NavLink to="/" title="DashBoard">
					<FaTachometerAlt />
				</NavLink>
			</div>
			<div>
				<span className={styles.pop}>{allUser}</span>
				<NavLink to="/AllUser" title="All Users">
					<FaUserAlt />
				</NavLink>
			</div>
			<div>
				<span className={styles.pop}>{pending}</span>
				<NavLink to="/Orders" title="Orders">
					<FaShoppingCart />
				</NavLink>
			</div>
			<div>
				<NavLink to="/ReportList" title="Report List">
					<GoReport />
				</NavLink>
			</div>
			{/* <div>
				<NavLink to="/VisitingCard" title="Visiting Card">
					<FaIdCard />
				</NavLink>
			</div> */}
			<div>
				<NavLink to="/RequestList" title="Request List">
					<FaClipboardList />
				</NavLink>
			</div>
			<div>
				<NavLink to="/Support" title="Support">
					<BiSupport />
				</NavLink>
			</div>
			{/* <div>
				<NavLink to="/Notification" title="Notification">
					<IoMdNotifications size={"25px"} />
				</NavLink>
			</div> */}
			<div
				onClick={() => {
					dispatch(logout());
					window.open("/", "_self");
				}}
				title="Logout">
				<img src={img5} alt="" />
			</div>
		</div>
	);
}
export default Header;
