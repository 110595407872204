import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import GetData from "../../components/hooks/GetData";
import axios from "../../components/hooks/axios";

const initialState = {
	allUsersDetails: {
		allUser: 0,
		userTypeCount: 0,
		professionalTypeCount: 0,
		adminTypeCount: 0,
		allUsers: [],
		error: false,
		isLoading: false,
		search: "",
		limit: 20,
	},
	oneUser: {},
	userClassData: {
		count: 0,
		classes: [],
		isLectureDeleted: false,
		isReviewDeleted: false,
		isClassDeleted: false,
	},
	postData: {
		postCount: 0,
		posts: [],
	},
	isLoading: false,
	isSuccess: false,
	isError: false,
	message: "",
};

export const fetchAllUser = createAsyncThunk("fetchAllUser", async ({ token, page, limit }) => {
	const result = await GetData(`/admin/getuserdetails?page=${page}&perPage=${limit}`, token);
	return result;
});

export const fetchOneUser = createAsyncThunk("fetchOneUser", async (body) => {
	const result = await GetData("/auth/get-user-details?user_id=" + body.id, body.token);
	return result;
});

export const fetchUserCreateClasses = createAsyncThunk("fetchUserCreateClasses", async (body) => {
	const result = await GetData("/class/getclassByUserid/" + body.id, body.token);
	return result;
});

export const fetchAllPosts = createAsyncThunk("fetchAllPosts", async (body) => {
	const result = await GetData(`/admin/getPost/${body.id}?offset=${body.offset}&type=`, body.token);
	return result;
});

export const deleteLecture = createAsyncThunk("deleteLecture", async (id) => {
	const result = await axios.delete("/class/deleteLacture/" + id);
	return result.data;
});

export const deleteReview = createAsyncThunk("deleteReview", async ({ classId, id }) => {
	const result = await axios.delete("admin/deleteReviewFromClass", {
		data: {
			class_id: classId,
			review_id: id,
		},
	});
	return result.data;
});

export const deleteClass = createAsyncThunk("deleteClass", async (id) => {
	const result = await axios.delete("/class/deleteClass", { data: { _id: id } });
	return result.data;
});

const actionSlice = createSlice({
	name: "action",
	initialState,
	reducers: {
		setSearch: (state, { payload }) => {
			state.allUsersDetails.search = payload;
		},
		setLimit: (state, { payload }) => {
			state.allUsersDetails.limit = payload;
		},
	},
	extraReducers: {
		[fetchAllUser.pending]: (state) => {
			state.isSuccess = false;
			state.allUsersDetails.isLoading = true;
			state.allUsersDetails.error = false;
		},
		[fetchAllUser.fulfilled]: (state, { payload }) => {
			state.allUsersDetails.isLoading = false;
			if (payload.status === true) {
				state.isSuccess = true;
				state.allUsersDetails.allUser = payload.totalUserCount;
				state.allUsersDetails.userTypeCount = payload.totalUserTypeCount;
				state.allUsersDetails.professionalTypeCount = payload.totalProfessionalTypeCount;
				state.allUsersDetails.adminTypeCount = payload.totalAdminTypeCount;
				state.allUsersDetails.allUsers = payload.data;
			} else {
				state.allUsersDetails.error = true;
				toast.error(payload.error);
			}
		},
		[fetchOneUser.pending]: (state) => {
			state.isLoading = true;
			state.isSuccess = false;
			state.isError = false;
		},
		[fetchOneUser.fulfilled]: (state, { payload }) => {
			state.isLoading = false;
			if (payload.status === true) {
				state.isSuccess = true;
				state.oneUser = payload.data.data;
			} else {
				state.isError = true;
				toast.error(payload.message);
			}
		},
		[fetchUserCreateClasses.pending]: (state) => {
			state.isLoading = true;
			state.isSuccess = false;
			state.isError = false;
		},
		[fetchUserCreateClasses.fulfilled]: (state, { payload }) => {
			state.isLoading = false;
			if (payload.status === true) {
				state.isSuccess = true;
				state.userClassData.count = payload.Count;
				state.userClassData.classes = payload.data;
			} else {
				state.isError = true;
				toast.error(payload.message);
			}
		},
		[fetchAllPosts.pending]: (state) => {
			state.isLoading = true;
			state.isSuccess = false;
			state.isError = false;
		},
		[fetchAllPosts.fulfilled]: (state, { payload }) => {
			state.isLoading = false;
			if (payload.status === true) {
				state.isSuccess = true;
				state.postData.postCount = payload.data.count.post;
				state.postData.posts = payload.data.data;
			} else {
				state.isError = true;
				toast.error(payload.message);
			}
		},
		[deleteLecture.pending]: (state) => {
			state.isLoading = true;
			state.isSuccess = false;
			state.isError = false;
			state.userClassData.isLectureDeleted = false;
		},
		[deleteLecture.fulfilled]: (state, { payload }) => {
			state.isLoading = false;
			if (payload.status === true) {
				state.isSuccess = true;
				state.userClassData.isLectureDeleted = true;
				toast.success("Successfully Lecture Deleted");
			} else {
				state.isError = true;
				toast.error(payload.message);
			}
		},
		[deleteReview.pending]: (state) => {
			state.isLoading = true;
			state.isSuccess = false;
			state.isError = false;
			state.userClassData.isReviewDeleted = false;
		},
		[deleteReview.fulfilled]: (state, { payload }) => {
			state.isLoading = false;
			if (payload.status === true) {
				state.isSuccess = true;
				state.userClassData.isReviewDeleted = true;
				toast.success("Successfully Review Deleted");
			} else {
				state.isError = true;
				toast.error(payload.message);
			}
		},
		[deleteClass.pending]: (state) => {
			state.isLoading = true;
			state.isSuccess = false;
			state.isError = false;
			state.userClassData.isClassDeleted = false;
		},
		[deleteClass.fulfilled]: (state, { payload }) => {
			state.isLoading = false;
			if (payload.status === true) {
				state.isSuccess = true;
				state.userClassData.isClassDeleted = true;
				toast.success("Successfully Class Deleted");
			} else {
				state.isError = true;
				toast.error(payload.message);
			}
		},
	},
});

export const { setSearch, setLimit } = actionSlice.actions;
export default actionSlice.reducer;
