const GetData = async (url, token) => {
	const response = await fetch(`https://musicbook.co.in/api/v1${url}`, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			authorization: token,
		},
	});

	return await response.json();
};
export default GetData;
