import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import GetData from "../../components/hooks/GetData";
import axios from "../../components/hooks/axios";

const initialState = {
	orders: [],
	orderCount: 0,
	approved: 0,
	rejected: 0,
	pending: 0,
	isLoading: false,
	isError: false,
	isSuccess: false,
	isStateChange:false,
	message: "",
};

export const fetchOrders = createAsyncThunk("fetchOrders", async ({token,offset=0}) => {
	const result = await GetData(`/admin/get-all-order?offset=${offset}`, token);
	return result;
});
export const fetchApproveStatus = createAsyncThunk("fetchApproveStatus", async (body) => {
	const result = await axios.put("/admin/update-status/" + body.orderID, {
		is_admin_approve: body.status,
	});
	return [result.data, body.status];
});

const orderSlice = createSlice({
	name: "order",
	initialState,
	reducers: {},
	extraReducers: {
		[fetchOrders.pending]: (state) => {
			state.isLoading = true;
			state.isSuccess = false;
			state.approved = 0;
			state.rejected = 0;
			state.pending = 0;
		},
		[fetchOrders.fulfilled]: (state, { payload }) => {
			state.isLoading = false;
			if (payload.status === true) {
				state.isSuccess = true;
				state.orders = payload.data;
				state.orderCount = payload.data.length;

				for (let i = 0; i < payload.data.length; i++) {
					if (payload.data[i]["is_admin_approve"] === "Yes") {
						state.approved++;
					} else if (payload.data[i]["is_admin_approve"] === "No") {
						state.rejected++;
					} else if (payload.data[i]["is_admin_approve"] === "") {
						state.pending++;
					}
				}
			} else {
				state.isError = true;
				toast.error(payload.message);
			}
		},
		[fetchApproveStatus.pending]: (state) => {
			state.isLoading = true;
			state.isSuccess = false;
			state.isStateChange = false;
		},
		[fetchApproveStatus.fulfilled]: (state, { payload }) => {
			state.isLoading = false;
			if (payload[0].status === true) {
				state.isSuccess = true;
				state.isStateChange = true;
				if (payload[1] === "Yes") toast.success("Approved !");
				else if (payload[1] === "No") toast.success("Rejected !");
				else if (payload[1] === "") toast.success("Reset !");
			} else {
				state.isError = true;
				toast.error(payload[0].message);
			}
		},
	},
});

export const {} = orderSlice.actions;
export default orderSlice.reducer;
