const FetchData = async (url, body={}, methods, token = "") => {

	const response = await fetch(`https://musicbook.co.in/api/v1${url}`, {
		method: methods,
		headers: {
            "Content-Type": "application/json",
		},
		body: JSON.stringify(body),
    });
    
	return await response.json();
};
export default FetchData;
