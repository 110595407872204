import React, { useEffect, useState } from "react";
import { BsFillFileEarmarkPostFill } from "react-icons/bs";
import { GrFormNext, GrFormPrevious } from "react-icons/gr";
import { MdError } from "react-icons/md";
import { RiFileUserFill } from "react-icons/ri";
import { CirclesWithBar } from "react-loader-spinner";
import { toast } from "react-toastify";
import userDefaultImage from "../assets/Images/userDefaultImage.jpg";
import styles from "../assets/scss/ReportList.module.scss";
import Header from "../components/Header";
import ReportListPopup from "../components/ReportListPopup";
import axios from "../components/hooks/axios";

const ReportList = () => {
	const [allReports, setAllReports] = useState([]);
	const [reportDetail, setReportDetail] = useState(null);
	const [reportListShow, setReportListShow] = useState(false);
	const [reportType, setReportType] = useState("");
	const [reportID, setReportID] = useState("");
	const [reload, setReload] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const [offset, setOffset] = useState(0);
	const [pageNumber, setPageNumber] = useState(1);

	useEffect(() => {
		setIsLoading(true);
		axios
			.get(`/admin/get-all-report?offset=${offset}&type=`)
			.then(({ data }) => {
				if (data.status === true) {
					setAllReports(data.data);
					setIsLoading(false);
				}
			})
			.catch((e) => console.log(e));
	}, [reload, offset]);

	return (
		<div className="display-flex">
			<Header />
			<div className="MainBodyContainer">
				<div className={styles.paginationContainer}>
					<h1>Report List</h1>
					<button
						disabled={offset === 0 ? true : false}
						onClick={() => {
							setOffset(offset - 10);
							setPageNumber(pageNumber - 1);
						}}>
						<GrFormPrevious className={styles.icon} /> Prev
					</button>
					<p>
						<span>{pageNumber}</span>
						page
					</p>
					<button
						disabled={allReports.length < 10 ? true : false}
						onClick={() => {
							setOffset(offset + 10);
							setPageNumber(pageNumber + 1);
						}}>
						Next <GrFormNext className={styles.icon} />
					</button>
				</div>

				{reportListShow && (
					<ReportListPopup
						setReportListShow={setReportListShow}
						reportDetail={reportDetail}
						reportType={reportType}
						reportID={reportID}
						setReload={setReload}
					/>
				)}
				<div className={`column-boxes ${styles.ReportListContainer}`}>
					{isLoading && (
						<CirclesWithBar
							height="100"
							width="100"
							color="#DD3333"
							wrapperStyle={{
								width: "100%",
								height: "90%",
								displayL: "flex",
								alignItems: "center",
								justifyContent: "center",
							}}
							wrapperClass=""
							visible={true}
							outerCircleColor=""
							innerCircleColor=""
							barColor=""
							ariaLabel="circles-with-bar-loading"
						/>
					)}
					{allReports.map((data, index) => {
						const time = new Date(data.created_at.toLocaleString(undefined, { timeZone: "Asia/Kolkata" }));
						let y = time.getFullYear();
						let m;
						let d;
						let h;
						let mi;
						let s;
						time.getMonth() + 1 < 10 ? (m = "0" + (time.getMonth() + 1)) : (m = parseInt(time.getMonth()) + 1);
						time.getDate() < 10 ? (d = "0" + time.getDate()) : (d = time.getDate());
						time.getHours() < 10 ? (h = "0" + time.getHours()) : (h = time.getHours());
						time.getMinutes() < 10 ? (mi = "0" + time.getMinutes()) : (mi = time.getMinutes());
						time.getSeconds() < 10 ? (s = "0" + time.getSeconds()) : (s = time.getSeconds());
						return (
							<div key={index} className={styles.ReportListRow}>
								<div>
									<h4 style={{ margin: "0px", marginRight:"15px" }}>{index + 1}</h4>
								</div>

								<div className="">
									<div className="profileImageContainer">
										<img
											src={data.report_by.pic ? data.report_by.pic : userDefaultImage}
											alt=""
											className="column-img"
										/>
									</div>
								</div>

								<div className={styles.ReportListColumn}>
									<p className="text-secondary">Reported by</p>
									<p className="Mt-5">{data.report_by.full_name}</p>
								</div>

								<div className={styles.ReportListColumn}>
									<p className="text-secondary">Report Time</p>
									<p>{y + "-" + m + "-" + d + " at " + h + ":" + mi + ":" + s}</p>
								</div>

								<div className={styles.ReportListColumn}>
									<p className="text-secondary">Report For</p>
									<p>{data.type}</p>
								</div>

								<div className={styles.ReportListColumn}>
									<p className="text-secondary">Report Type</p>
									<p className="Mt-5">{data.report_type}</p>
								</div>

								<div className={styles.ReportListColumn}>
									<p className="text-secondary">Report Text</p>
									<p className="Mt-5">{data.report_text}</p>
								</div>

								<div className={styles.ReportListColumn}>
									<p className="text-secondary">Resolved Status</p>
									<p className="Mt-5">{data.is_resolved ? "Solved" : "Not Solved"}</p>
								</div>

								<div className={styles.ReportListColumn}>
									<p className="text-secondary">See {data.post_id ? "Post" : data.user_id ? "User" : null}</p>
									<div className={styles.ReportListSeeIcon}>
										{data.post_id ? (
											<BsFillFileEarmarkPostFill
												onClick={() => {
													setReportDetail(data.post_id);
													setReportType("post");
													setReportID(data._id);
													setReportListShow(true);
												}}
											/>
										) : data.user_id ? (
											<RiFileUserFill
												onClick={() => {
													setReportDetail(data.user_id);
													setReportType("user");
													setReportID(data._id);
													setReportListShow(true);
												}}
											/>
										) : (
											<MdError onClick={() => toast("False Report")} />
										)}
									</div>
								</div>
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
};

export default ReportList;
