import React, { useEffect, useState } from "react";
import { GrFormNext, GrFormPrevious } from "react-icons/gr";
import { ImTicket } from "react-icons/im";
import { CirclesWithBar } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import styles from "../assets/scss/Support.module.scss";
import ChatPopup from "../components/ChatPopup";
import Header from "../components/Header";
import TicketPopup from "../components/TicketPopup";
import axios from "../components/hooks/axios";
import { getSupport, setActiveUser } from "../redux/slices/supportSlice";

const Support = () => {
	const [offset, setOffset] = useState(0);
	const [pageNumber, setPageNumber] = useState(1);
	const { token } = useSelector((s) => s.user);
	const { support, isLoading } = useSelector((state) => state.support);
	const dispatch = useDispatch();
	const [activeContainer, setActiveContainer] = useState("Email");
	const [ticketPopup, setTicketPopup] = useState(false);
	const [openChat, setOpenChat] = useState(false);
	const [ticketData, setTicketData] = useState([]);
	const [reload, setReload] = useState(0)

	useEffect(() => {
		dispatch(getSupport());
	}, []);

	useEffect(() => {
		axios
			.get("/admin/get-all-ticket")
			.then(({ data }) => {
				if (data.status) {
					setTicketData(data.data);
				}
			})
			.catch((e) => {
				console.log(e);
			});
	}, [reload]);

	const statusChange = (id) => {
		axios
			.get(`/ticket/statusChange/${id}`)
			.then(({ data }) => {
				if (data.status) {
					setReload(Math.random());
				}
			})
			.catch((e) => {
				console.log(e);
			});
	}

	return (
		<div className="display-flex">
			<Header />
			<div className="MainBodyContainer">
				{ticketPopup && <TicketPopup setTicketPopup={setTicketPopup} setOpenChat={setOpenChat} />}
				{openChat && <ChatPopup setOpenChat={setOpenChat} setTicketPopup={setTicketPopup} />}

				<div className={styles.paginationContainer}>
					<h1>Supports</h1>
					<button
						disabled={offset === 0 ? true : false}
						onClick={() => {
							setOffset(offset - 10);
							setPageNumber(pageNumber - 1);
						}}>
						<GrFormPrevious className={styles.icon} /> Prev
					</button>
					<p>
						<span>{pageNumber}</span>
						page
					</p>
					<button
						disabled={support.length < 10 ? true : false}
						onClick={() => {
							setOffset(offset + 10);
							setPageNumber(pageNumber + 1);
						}}>
						Next <GrFormNext className={styles.icon} />
					</button>
				</div>

				<div className={styles.SupportHeader}>
					<div className={styles.Left}>
						<h1>Supports</h1>
					</div>
					<div className={styles.Right}>
						<button
							onClick={() => setActiveContainer("Email")}
							style={{ background: activeContainer === "Email" ? "#008aa2" : "" }}>
							Email
						</button>
						<button
							onClick={() => setActiveContainer("Ticket")}
							style={{ background: activeContainer === "Ticket" ? "#008aa2" : "" }}>
							All Tickets
						</button>
					</div>
				</div>

				<div className={styles.Support}>
					{isLoading && (
						<CirclesWithBar
							height="100"
							width="100"
							color="#DD3333"
							wrapperStyle={{
								width: "100%",
								height: "90%",
								displayL: "flex",
								alignItems: "center",
								justifyContent: "center",
							}}
							wrapperClass=""
							visible={true}
							outerCircleColor=""
							innerCircleColor=""
							barColor=""
							ariaLabel="circles-with-bar-loading"
						/>
					)}
					{activeContainer === "Email" ? (
						<>
							{support.map((data, index) => {
								const time = new Date(data.createdAt.toLocaleString(undefined, { timeZone: "Asia/Kolkata" }));
								let y = time.getFullYear();
								let m;
								let d;
								let h;
								let mi;
								let s;
								time.getMonth() + 1 < 10
									? (m = "0" + (time.getMonth() + 1))
									: (m = parseInt(time.getMonth()) + 1);
								time.getDate() < 10 ? (d = "0" + time.getDate()) : (d = time.getDate());
								time.getHours() < 10 ? (h = "0" + time.getHours()) : (h = time.getHours());
								time.getMinutes() < 10 ? (mi = "0" + time.getMinutes()) : (mi = time.getMinutes());
								time.getSeconds() < 10 ? (s = "0" + time.getSeconds()) : (s = time.getSeconds());
								return (
									<div key={index} className={styles.supportRow}>
										<div className={styles.column}>
											<h6>{index + 1}</h6>
										</div>
										<div className={styles.column}>
											<h6>Email</h6>
											<p>{data.email}</p>
										</div>
										<div className={styles.column}>
											<h6>Reason</h6>
											<p>{data.reason}</p>
										</div>
										<div className={`${styles.column} ${styles.message}`}>
											<h6>Message</h6>
											<p>{data.message}</p>
										</div>
										<div className={styles.column}>
											<h6>Created at</h6>
											<p>{y + "-" + m + "-" + d + " at " + h + ":" + mi + ":" + s}</p>
										</div>
									</div>
								);
							})}
						</>
					) : (
						<>
							{[...ticketData].reverse().map((data, index) => {
									return (
										<div
											key={index}
											className={styles.supportRow}
											style={{ cursor: "pointer" }}
											onClick={() => {
												setTicketPopup(true);
												dispatch(setActiveUser(data.userData));
											}}>
											<div className={styles.column}>
												<p>{index + 1}</p>
											</div>
											<div className={styles.column}>
												<img src={data.userData.pic} alt="data" />
											</div>
											<div className={`${styles.column} ${styles.name}`}>
												<h6>Name</h6>
												<p>{data.userData.full_name}</p>
											</div>
											<div className={styles.column}>
												<h6>Date</h6>
												<p>{data.userData.createdAt.split("T")[0]}</p>
											</div>
											<div className={`${styles.column} ${styles.email}`}>
												<h6>Email</h6>
												<p>{data.userData.email}</p>
											</div>
											<div className={styles.column}>
												<h6>Ticket Raise</h6>
												<p>
													<ImTicket color="#FFB800" size={"22px"} /> {data.tickets.length} Ticket Rise
												</p>
											</div>
											<div
												className={`${styles.column} ${styles.btnCont}`}
												onClick={(e) => e.stopPropagation()}>
												<button
													className={
														[...data.tickets].reverse()[0].ticketStaus ? styles.unresolved : ""
													}>
													unresolved
												</button>
												<button
													className={[...data.tickets].reverse()[0].ticketStaus ? "" : styles.Resolved}
													onClick={() => statusChange([...data.tickets].reverse()[0]._id)}>
													Resolved
												</button>
											</div>
										</div>
									);
							})}
						</>
					)}
				</div>
			</div>
		</div>
	);
};

export default Support;
