import React from "react";
import Header from "../components/Header";

const Notification = () => {
	return (
		<div className="display-flex">
			<Header />

			<div className="MainBodyContainer">Notification</div>
		</div>
	);
};

export default Notification;
