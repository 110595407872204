import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import FetchData from "../../components/hooks/FetchData";

const initialState = {
	user: null,
	token: null,
	userType: "",
	isLoading: false,
	isSuccess: false,
	isError: false,
	message: "",
};

export const signInUser = createAsyncThunk("signInUser", async (data) => {
	let body = {
		email: data.email,
		password: data.password,
		user_type: data.user_type,
	};

	const result = await FetchData("/admin/login", body, "POST");
	return result;
});

const authSlice = createSlice({
	name: "user",
	initialState,
	reducers: {
		setInLocalStorage: (state) => {
			state.user = JSON.parse(localStorage.getItem("admin_data"));
			state.token = localStorage.getItem("admin_token");
			state.userType = JSON.parse(localStorage.getItem("admin_data"))?.user_type;
			state.isSuccess = true;

		},
		logout: (state) => {
			state.user = null;
			state.token = null;
			state.isLoading = false;
			state.isSuccess = false;
			state.isError = false;
			state.message = "";
			localStorage.removeItem("admin_token");
			localStorage.removeItem("admin_data");
			toast.success("Successfully logged out");
		},
	},
	extraReducers: {
		[signInUser.pending]: (state, action) => {
			state.isLoading = true;
		},
		[signInUser.fulfilled]: (state, { payload }) => {
			state.isLoading = false;
			if (payload.status === true) {
				window.open("/", "_self")
				state.isSuccess = true;
				state.user = payload.data;
				state.token = payload.token;
				state.userType = state.user.user_type;
				state.message = payload.message;
				localStorage.setItem("admin_token", payload.token);
				localStorage.setItem("admin_data", JSON.stringify(payload.data));
				toast.success("Successfully logged in");
			} else {
				state.isError = true;
				state.message = payload.message;
				toast.error(payload.message);
			}
		},
	},
});


export const { setInLocalStorage, logout } = authSlice.actions;
export default authSlice.reducer;
