import { useEffect, useState } from "react";
import { CirclesWithBar } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import searchIcon from "../assets/Images/searchIcon.svg";
import userDefaultImage from "../assets/Images/userDefaultImage.jpg";
import styles from "../assets/scss/AllUser.module.scss";
import Header from "../components/Header";
import { fetchAllUser, setLimit, setSearch } from "../redux/slices/actionSlice";

function AllUser() {
	const navigate = useNavigate();
	const [userType, setUserType] = useState("");
	const [page, setPage] = useState(1);
	const { token, isSuccess } = useSelector((state) => state.user);
	const { allUsers, allUser, userTypeCount, professionalTypeCount, adminTypeCount, isLoading, error,search, limit } = useSelector(
		(state) => state.action.allUsersDetails
	);
	const dispatch = useDispatch();

	useEffect(() => {
		if (isSuccess && token) {
			dispatch(fetchAllUser({ token, page, limit }));
		}
	}, [token, page, limit]);

	const filterUsersDATA = allUsers.filter(
		(user) =>
			user?.full_name?.toLowerCase().includes(search?.toLowerCase()) || user?.email?.toLowerCase().includes(search?.toLowerCase())
	);

	const UserColumn = ({ user, index }) => {
		return (
			<div className={styles.columnBox} onClick={() => navigate("/UserDetails/" + user._id)}>
				<div style={{ width: "30px", textAlign: "center" }}>
					<h4>{index + 1}</h4>
				</div>
				<div className={styles.profileImageContainer}>
					<img src={user.pic ? user.pic : userDefaultImage} alt="" className={styles.columnImg} />
				</div>
				<div style={{ width: "160px" }}>
					<p>Name</p>
					<div className={styles.Mt5}>{user.full_name}</div>
				</div>
				<div style={{ width: "160px" }}>
					<p>Visiting Card</p>
					<div className={styles.Mt5}>{"none"}</div>
				</div>
				<div style={{ width: "300px" }}>
					<p>Email</p>
					<div className={styles.Mt5}>{user.email}</div>
				</div>
				<div style={{ width: "130px" }}>
					<p>Contact</p>
					<div className={styles.Mt5}>{user.mobile_number ? user.mobile_number : "0000000000"}</div>
				</div>
				<div style={{ width: "110px" }}>
					<p>User Type</p>
					<div className={styles.Mt5}>{user.user_type}</div>
				</div>
				<div style={{ width: "110px" }}>
					<p>user Status</p>
					<div className={styles.Mt5}>{user.is_block ? "Blocked" : "Active"}</div>
				</div>
				{/* <div style={{ width: "20px" }}>
					<img
						src={eye}
						alt=""
						onClick={(e) => {
							e.stopPropagation();
							setUser(user);
							setShow(true);
						}}
						style={{ cursor: "pointer" }}
					/>
				</div> */}
				{/* <div
					style={{ width: "50px", textAlign: "center", cursor: "pointer" }}
					onClick={(e) => {
						e.stopPropagation();
						setUser(user);
						setPostShow(true);
					}}>
					Posts
				</div> */}
			</div>
		);
	};

	return (
		<div className="display-flex">
			<Header />
			<div className={`MainBodyContainer ${styles.AllUser}`}>
				<div className="display-flex" style={{ justifyContent: "space-between" }}>
					<h4>All Users</h4>
					<div className={styles.searchContainer}>
						<img src={searchIcon} alt="" />
						<input
							type="search"
							placeholder="Search user by name or email"
							value={search}
							onChange={(e) => dispatch(setSearch(e.target.value))}
							// onChange={(e) => setSearch(e.target.value)}
						/>
					</div>
				</div>

				<div className="display-flex" style={{ justifyContent: "space-between", marginTop: "1%" }}>
					<div className={styles.manageRGB} onClick={() => setUserType("")}>
						<h1>All User</h1>
						<h1>{isLoading ? "Loading.." : allUser}</h1>
					</div>
					<div className={styles.manageRGB} onClick={() => setUserType("user")}>
						<h1>Personal</h1>
						<h1>{isLoading ? "Loading.." : userTypeCount}</h1>
					</div>
					<div className={styles.manageRGB} onClick={() => setUserType("professional")}>
						<h1>Professional</h1>
						<h1>{isLoading ? "Loading.." : professionalTypeCount}</h1>
					</div>
					<div className={styles.manageRGB} onClick={() => setUserType("admin")}>
						<h1>Admin</h1>
						<h1>{isLoading ? "Loading.." : adminTypeCount}</h1>
					</div>
				</div>
				<br />

				<div className={styles.columnBoxes}>
					{isLoading ? (
						<CirclesWithBar
							height="100"
							width="100"
							color="#DD3333"
							wrapperStyle={{
								width: "100%",
								height: "90%",
								displayL: "flex",
								alignItems: "center",
								justifyContent: "center",
							}}
							wrapperClass=""
							visible={true}
							outerCircleColor=""
							innerCircleColor=""
							barColor=""
							ariaLabel="circles-with-bar-loading"
						/>
					) : filterUsersDATA.length == 0 ? (
						<h1
							style={{
								width: "100%",
								marginTop: "100px",
								textAlign: "center",
							}}>
							No Data Found
						</h1>
					) : (
						filterUsersDATA.map((user, index) => {
							if (user.user_type == userType) return <UserColumn user={user} index={index} key={index} />;
							else if (userType == "" && user.user_type != "SuperAdmin")
								return <UserColumn user={user} index={index} key={index} />;
						})
					)}
				</div>

				<div className={styles.ButtonContainer}>
					<button
						disabled={page === 1 ? true : false}
						onClick={() => {
							setPage(page - 1);
						}}>
						{"<"} Prev
					</button>
					<p>
						{" "}
						<span>{page} </span> Page
					</p>
					<button
						disabled={error}
						onClick={() => {
							setPage(page + 1);
						}}>
						Next {">"}
					</button>

					<div>
						Limit:
						<input type="number" value={limit} onChange={(e) => dispatch(setLimit(e.target.value))} />
					</div>
				</div>
			</div>
		</div>
	);
}
export default AllUser;
