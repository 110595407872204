import React from "react";
import Header from "../components/Header";

const RequestList = () => {
	return (
		<div className="display-flex">
			<Header />
			<div className="MainBodyContainer">RequestList</div>
		</div>
	);
};

export default RequestList;
