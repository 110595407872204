import React, { useState } from "react";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { MagnifyingGlass } from "react-loader-spinner";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import emailIcon from "../assets/Images/emailIcon.svg";
import lockIcon from "../assets/Images/lockIcon.svg";
import styles from "../assets/scss/Login&Register.module.scss";
import { signInUser } from "../redux/slices/userSlice";

const Login = () => {
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [showPass, setShowPass] = useState("password");
	const [rememberMe, setRememberMe] = useState(true);
	const { isLoading } = useSelector((state) => state.user);
	const dispatch = useDispatch();

	const handelLogin = () => {
		if (email.length === 0 || password.length === 0) {
			return toast.warn("Please fill all field");
		}
		const result = dispatch(
			signInUser({
				email: email,
				password: password,
				user_type: email === "support@musicbook.co.in" ? "superAdmin" : "admin",
				rememberMe,
			})
		);
		console.log(result)
	};

	return (
		<div className={styles.MainContainer}>
			<div className={styles.OverlayContainer} />

			<div className={styles.TextContainer}>
				<h1>Admin Panel</h1>
				<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pharetra velit quisque tristique cursus purus.</p>
				<div className="DesignDot">
					<div />
					<div />
					<div />
				</div>
			</div>
			<div className={styles.BoxContainer}>
				<div className={styles.BoxText}>
					<h1>Login</h1>
					<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam consectetur euismod.</p>
				</div>
				<div className={styles.BoxBottom}>
					<div className={styles.inputGroup}>
						<div className={styles.inputField}>
							<label htmlFor="email">
								<img src={emailIcon} alt="" />
							</label>
							<input
								type="email"
								name="email"
								id="email"
								placeholder="Email"
								value={email}
								onChange={(e) => setEmail(e.target.value)}
							/>
						</div>
					</div>
					<div className={styles.inputGroup}>
						<div className={styles.inputField}>
							<label htmlFor="password">
								<img src={lockIcon} alt="" />
							</label>
							<input
								type={showPass}
								name="password"
								id="password"
								placeholder="Password"
								value={password}
								onChange={(e) => setPassword(e.target.value)}
							/>
							{showPass === "password" ? (
								<AiFillEye className={styles.eyePassLogin} onClick={() => setShowPass("text")} />
							) : (
								<AiFillEyeInvisible className={styles.eyePassLogin} onClick={() => setShowPass("password")} />
							)}
						</div>
					</div>

					<div className={`${styles.inputGroup} ${styles.radio} ${styles.log}`}>
						<div>
							<input
								type="radio"
								name="accept"
								id="accept"
								checked={rememberMe}
								onChange={(e) => setRememberMe(e.target.checked)}
							/>
							<label htmlFor="accept">Remember Me</label>
						</div>
						<Link to="/">Forget Password ?</Link>
					</div>

					<div className={`${styles.inputGroup} ${styles.buttonC}`}>
						<button type="button" onClick={handelLogin} disabled={isLoading}>
							{isLoading ? "" : "CONTINUE"}
							<MagnifyingGlass
								visible={isLoading}
								height="70"
								width="70"
								ariaLabel="MagnifyingGlass-loading"
								wrapperStyle={{}}
								wrapperClass="MagnifyingGlass-wrapper"
								glassColor="#c0efff"
								color="#e15b64"
							/>
						</button>
					</div>

					<div className={`${styles.inputGroup} ${styles.sign}`}>
						<label htmlFor="accept">
							Don't have an account ? Contact <Link>support@musicbook.co.in</Link>
						</label>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Login;
