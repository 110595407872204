import React, { useState } from "react";
import { AiFillEye, AiFillEyeInvisible, AiOutlineUser } from "react-icons/ai";
import { Vortex } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import emailIcon from "../assets/Images/emailIcon.svg";
import lockIcon from "../assets/Images/lockIcon.svg";
import userIcon from "../assets/Images/userIcon.svg";
import styles from "../assets/scss/Login&Register.module.scss";

const Register = () => {
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [password2, setPassword2] = useState("");
	const [type, setType] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const [isValid, setIsValid] = useState(true);
	const [showPass, setShowPass] = useState("password");
	const [showConPass, setShowConPass] = useState("password");

	const navigate = useNavigate();

	async function postData(url = "", data = {}, methods = "") {
		const response = await fetch(url, {
			method: methods,
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(data),
		});
		return response.json();
	}

	const handleEmailChange = (event) => {
		setEmail(event.target.value);
		setIsValid(validateEmail(event.target.value));
	};

	const validateEmail = (email) => {
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return emailRegex.test(email);
	};

	const handelRegister = () => {
		if (name.length === 0 || email.length === 0 || password.length === 0 || password2.length === 0)
			return toast.warn("Please fill all field");
		if (isValid === false) return toast.warn("Please fill all field");
		if (password !== password2) return toast.warn("Password not match");
		if (type === "") return toast.warn("Please select user type");

		setIsLoading(true);

		let data = {
			full_name: name,
			email: email,
			password: password,
			user_type: type,
		};

		postData("https://musicbook.co.in/api/v1/admin/register", data, "POST")
			.then((data) => {
				console.log(data)
				if (data.status === true) {
					console.log("data=> ", data);
					toast.success("Successfully Register !");
					setIsLoading(false);
					navigate("/Dashboard");
				}  else {
					toast.error(data.message);
					setIsLoading(false);
				}
			})
			.catch((e) => {
				toast.error(e.message);
				setIsLoading(false);
			});
	};

	return (
		<div className={styles.MainContainer}>
			<div className={`${styles.OverlayContainer} ${styles.reg}`} />

			<div className={styles.TextContainer}>
				<h1>Admin Panel</h1>
				<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pharetra velit quisque tristique cursus purus.</p>
				<div className={styles.DesignDot}>
					<div />
					<div />
					<div />
				</div>
			</div>

			<div className={styles.BoxContainer}>
				<div className={styles.BoxText}>
					<h1>Register</h1>
					<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam consectetur euismod.</p>
				</div>
				<div className={styles.BoxBottom}>
					<div className={styles.inputGroup}>
						<div className={styles.inputField}>
							<label htmlFor="name">
								<img src={userIcon} alt="" />
							</label>
							<input
								type="text"
								name="name"
								id="name"
								placeholder="Name"
								value={name}
								onChange={(e) => setName(e.target.value)}
							/>
						</div>
					</div>

					<div className={styles.inputGroup}>
						<div className={styles.inputField}>
							<label htmlFor="email">
								<img src={emailIcon} alt="" />
							</label>
							<input
								type="email"
								name="email"
								id="email"
								placeholder="Email"
								value={email}
								onChange={handleEmailChange}
							/>
						</div>
					</div>

					<div className={styles.inputGroup}>
						<div className={styles.inputField}>
							<label htmlFor="password">
								<img src={lockIcon} alt="" />
							</label>
							<input
								type={showPass}
								name="password"
								id="password"
								placeholder="Password"
								value={password}
								onChange={(e) => setPassword(e.target.value)}
							/>
							{showPass === "password" ? (
								<AiFillEye className={styles.eyePassLogin} onClick={() => setShowPass("text")} />
							) : (
								<AiFillEyeInvisible className={styles.eyePassLogin} onClick={() => setShowPass("password")} />
							)}
						</div>
					</div>

					<div className={styles.inputGroup}>
						<div className={styles.inputField}>
							<label htmlFor="cPassword">
								<img src={lockIcon} alt="" />
							</label>
							<input
								type={showConPass}
								name="cPassword"
								id="cPassword"
								placeholder="Confirm Password"
								value={password2}
								onChange={(e) => setPassword2(e.target.value)}
							/>
							{showConPass === "password" ? (
								<AiFillEye className={styles.eyePassLogin} onClick={() => setShowConPass("text")} />
							) : (
								<AiFillEyeInvisible className={styles.eyePassLogin} onClick={() => setShowConPass("password")} />
							)}
						</div>
					</div>

					<div className={styles.inputGroup}>
						<div className={styles.inputField}>
							<label htmlFor="cPassword">
								<AiOutlineUser />
							</label>

							<select defaultValue={type} onChange={(e) => setType(e.target.value)}>
								<option value="" disabled>
									Select user type
								</option>
								<option value="admin">Admin</option>
								<option value="superAdmin">Super Admin</option>
							</select>
						</div>
					</div>

					<div className={`${styles.inputGroup} ${styles.buttonC}`}>
						<button type="button" onClick={handelRegister} disabled={isLoading}>
							{isLoading ? "" : "CONTINUE"}
							<Vortex
								visible={isLoading}
								height="50"
								width="50"
								ariaLabel="vortex-loading"
								wrapperStyle={{}}
								wrapperClass="vortex-wrapper"
								colors={["red", "green", "blue", "yellow", "orange", "purple"]}
							/>
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Register;
