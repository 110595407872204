import { useState } from "react";
import bell from "../assets/Images/bell.png";

import Header from "./Header";

function Bidding() {
	const [show, setShow] = useState(false);
	return (
		<div className="display-flex">
			<Header />
			<div className="MainBodyContainer">
				<div className="display-flex" style={{ justifyContent: "space-between" }}>
					<h4>All Notifications</h4>
					<img src={bell} alt="" className="bell" />
				</div>

				{/* <br /> */}

				{/* <UserDetailPopup show={show} onClose={() => setShow(false)} /> */}

				<div className="column-boxes">
					<div className="column-box-notification">hello,Good News Now You Can Buy Visiting Cards only @200/-</div>
					<div className="column-box-notification">hello,Good News Now You Can Buy Visiting Cards only @200/-</div>
					<div className="column-box-notification">hello,Good News Now You Can Buy Visiting Cards only @200/-</div>
					<div className="column-box-notification">hello,Good News Now You Can Buy Visiting Cards only @200/-</div>
					<div className="column-box-notification">hello,Good News Now You Can Buy Visiting Cards only @200/-</div>
					<div className="column-box-notification">hello,Good News Now You Can Buy Visiting Cards only @200/-</div>
					<div className="column-box-notification">hello,Good News Now You Can Buy Visiting Cards only @200/-</div>
					<div className="column-box-notification">hello,Good News Now You Can Buy Visiting Cards only @200/-</div>
					<div className="column-box-notification">hello,Good News Now You Can Buy Visiting Cards only @200/-</div>
					<div className="column-box-notification">hello,Good News Now You Can Buy Visiting Cards only @200/-</div>
					<div className="column-box-notification">hello,Good News Now You Can Buy Visiting Cards only @200/-</div>
					<div className="column-box-notification">hello,Good News Now You Can Buy Visiting Cards only @200/-</div>
					<div className="column-box-notification">hello,Good News Now You Can Buy Visiting Cards only @200/-</div>
					<div className="column-box-notification">hello,Good News Now You Can Buy Visiting Cards only @200/-</div>
					<div className="column-box-notification">hello,Good News Now You Can Buy Visiting Cards only @200/-</div>
					<div className="column-box-notification">hello,Good News Now You Can Buy Visiting Cards only @200/-</div>
					<div className="column-box-notification">hello,Good News Now You Can Buy Visiting Cards only @200/-</div>
					<div className="column-box-notification">hello,Good News Now You Can Buy Visiting Cards only @200/-</div>
					<div className="column-box-notification">hello,Good News Now You Can Buy Visiting Cards only @200/-</div>
					<div className="column-box-notification">hello,Good News Now You Can Buy Visiting Cards only @200/-</div>
					<div className="column-box-notification">hello,Good News Now You Can Buy Visiting Cards only @200/-</div>
				</div>
			</div>
		</div>
	);
}
export default Bidding;
