import axios from "axios";

const Instance = axios.create({
	baseURL: "https://musicbook.co.in/api/v1",
	// baseURL: "http://localhost:3666/api/v1",
	headers: {
		// "Content-Type": "application/json",
		authorization: localStorage.getItem("admin_token"),
	},
});

export default Instance;
