import React, { useEffect, useState } from "react";
import { GrFormNext, GrFormPrevious } from "react-icons/gr";
import { useDispatch, useSelector } from "react-redux";
import styles from "../assets/scss/Orders.module.scss";
import Header from "../components/Header";
import { fetchApproveStatus, fetchOrders } from "../redux/slices/orderSlice";

const Orders = () => {
	const [offset, setOffset] = useState(0);
	const [pageNumber, setPageNumber] = useState(1);
	const [popupImage, setPopupImage] = useState(null);
	const [search, setSearch] = useState("");
	const [pendingStatus, setPendingStatus] = useState(false);
	const { token } = useSelector((s) => s.user);
	const { orders, orderCount, approved, rejected, pending, isStateChange } = useSelector((state) => state.order);
	const dispatch = useDispatch();

	useEffect(() => {
		if (isStateChange || offset > 0) dispatch(fetchOrders({ token, offset }));
	}, [isStateChange, offset]);

	const handelApprove = (orderID, status) => {
		dispatch(fetchApproveStatus({ orderID, status }));
	};

	const filterOrdersDATA = orders.filter((order) => {
		if (pendingStatus) {
			return order.is_admin_approve == "";
		} else {
			return order.is_admin_approve.includes(search);
		}
	});

	return (
		<div className="display-flex">
			<Header />
			{popupImage && (
				<div className={styles.popupImage}>
					<button onClick={() => setPopupImage(null)}>X</button>
					<div className={styles.inner}>
						<img src={popupImage} alt="" />
					</div>
				</div>
			)}
			<div className="MainBodyContainer">
				<div className={styles.paginationContainer}>
					<h1>Orders</h1>
					<button
						disabled={offset === 0 ? true : false}
						onClick={() => {
							setOffset(offset - 10);
							setPageNumber(pageNumber - 1);
						}}>
						<GrFormPrevious className={styles.icon} /> Prev
					</button>
					<p>
						<span>{pageNumber}</span>
						page
					</p>
					<button
						disabled={orders.length < 10 ? true : false}
						onClick={() => {
							setOffset(offset + 10);
							setPageNumber(pageNumber + 1);
						}}>
						Next <GrFormNext className={styles.icon} />
					</button>
				</div>

				<div className={styles.FilterBox}>
					<div
						className={styles.manageRGB}
						onClick={() => {
							setPendingStatus(false);
							setSearch("");
						}}>
						<h1>Total Orders</h1>
						<h1>{orderCount}</h1>
					</div>
					<div
						className={styles.manageRGB}
						onClick={() => {
							setPendingStatus(false);
							setSearch("Yes");
						}}>
						<h1>Approved</h1>
						<h1>{approved}</h1>
					</div>
					<div
						className={styles.manageRGB}
						onClick={() => {
							setPendingStatus(false);
							setSearch("No");
						}}>
						<h1>Rejected</h1>
						<h1>{rejected}</h1>
					</div>
					<div
						className={styles.manageRGB}
						onClick={() => {
							setPendingStatus(true);
							setSearch("");
						}}>
						<h1>Pending</h1>
						<h1>{pending}</h1>
					</div>
				</div>

				<div className={styles.detailsContainer}>
					{filterOrdersDATA.map((data, index) => {
						const time = new Date(data.created_at.toLocaleString(undefined, { timeZone: "Asia/Kolkata" }));
						let y = time.getFullYear();
						let m;
						let d;
						let h;
						let mi;
						let s;
						time.getMonth() + 1 < 10 ? (m = "0" + (time.getMonth() + 1)) : (m = parseInt(time.getMonth()) + 1);
						time.getDate() < 10 ? (d = "0" + time.getDate()) : (d = time.getDate());
						time.getHours() < 10 ? (h = "0" + time.getHours()) : (h = time.getHours());
						time.getMinutes() < 10 ? (mi = "0" + time.getMinutes()) : (mi = time.getMinutes());
						time.getSeconds() < 10 ? (s = "0" + time.getSeconds()) : (s = time.getSeconds());
						return (
							<div key={index} className={styles.columnBox}>
								<div className={styles.column}>
									<h6>{index + 1}</h6>
								</div>
								<div className={styles.column}>
									<img className={styles.ProfileImg} src={data?.user_id?.pic} alt="" />
								</div>
								<div className={styles.column}>
									<h6>Order By</h6>
									<p>{data?.user_id?.full_name}</p>
								</div>
								<div className={styles.column}>
									<h6>Purchased at</h6>
									<p>{y + "-" + m + "-" + d + " at " + h + ":" + mi + ":" + s}</p>
								</div>
								<div className={styles.column}>
									<h6>Amount</h6>
									<p>{data?.amount}</p>
								</div>
								<div className={styles.column}>
									<h6>Card id</h6>
									<p>{data?.card_id?.card_id}</p>
								</div>
								<div className={styles.column}>
									<h6>Image proof</h6>
									{data?.proof_image === "" ? (
										<p>No Image</p>
									) : (
										<img
											className={styles.ProofImg}
											src={data?.proof_image}
											alt=""
											onClick={() => setPopupImage(data.proof_image)}
										/>
									)}
								</div>
								<div className={`${styles.column} ${styles.buttonContainer}`}>
									{data.is_admin_approve === "" ? (
										<>
											<button type="button" onClick={() => handelApprove(data._id, "Yes")}>
												Approve
											</button>
											<button type="button" onClick={() => handelApprove(data._id, "No")}>
												Reject
											</button>
										</>
									) : data.is_admin_approve === "Yes" ? (
										<>
											<h3 className={styles.Yes}>Approved</h3>
											<button
												className={styles.reset}
												type="button"
												onClick={() => handelApprove(data._id, "")}>
												Reset
											</button>
										</>
									) : (
										<>
											<h3 className={styles.No}>Rejected</h3>
											<button
												className={styles.reset}
												type="button"
												onClick={() => handelApprove(data._id, "")}>
												Reset
											</button>
										</>
									)}
								</div>
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
};

export default Orders;
