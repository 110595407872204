import React from "react";
import Header from "../components/Header";

const VisitingCard = () => {
	return (
		<div className="display-flex">
			<Header />
			<div className="MainBodyContainer">VisitingCard</div>
		</div>
	);
};

export default VisitingCard;
