import React, { useEffect, useState } from "react";
import { ImTicket } from "react-icons/im";
import { useDispatch, useSelector } from "react-redux";
import styles from "../assets/scss/Support.module.scss";
import axios from "../components/hooks/axios";
import { setActiveTicketId, setActiveUser } from "../redux/slices/supportSlice";
import { CirclesWithBar } from "react-loader-spinner";

const TicketPopup = ({ setTicketPopup, setOpenChat }) => {
	const { activeUser } = useSelector((state) => state.support);
	const dispatch = useDispatch();
	const [ticketData, setTicketData] = useState([]);
	const [loading, setLoading] = useState(false)
	const month = [
		"January",
		"February",
		"March",
		"April",
		"May",
		"June",
		"July",
		"August",
		"September",
		"October",
		"November",
		"December",
	];

	useEffect(() => {
		if (activeUser === {}) return;
		setLoading(true);
		axios
			.get(`/ticket/get/${activeUser._id}`)
			.then(({ data }) => {
				if (data.status) {
					setLoading(false);
					setTicketData(data.data);
				}
			})
			.catch((e) => {
				console.log(e);
			});
	}, [activeUser]);

	return (
		<div
			className={styles.PopupBackground}
			onClick={() => {
				setTicketPopup(false);
				setOpenChat(false);
				dispatch(setActiveUser({}));
			}}>
			<div className={styles.TicketPopup} onClick={(e) => e.stopPropagation()}>
				<div className={styles.Top}>
					<img src={activeUser.pic} alt="" />
					<h2>{activeUser.full_name}</h2>
				</div>

				<h1>Ticket Raise</h1>
				<div className={styles.Bottom}>
					{loading && (
						<CirclesWithBar
							height="100"
							width="100"
							color="#DD3333"
							wrapperStyle={{
								width: "100%",
								height: "90%",
								displayL: "flex",
								alignItems: "center",
								justifyContent: "center",
							}}
							wrapperClass=""
							visible={true}
							outerCircleColor=""
							innerCircleColor=""
							barColor=""
							ariaLabel="circles-with-bar-loading"
						/>
					)}
					{[...ticketData].reverse().map((data, index) => {
						const date = new Date(data.createdAt);
						return (
							<div
								className={styles.TicketBox}
								key={index}
								onClick={() => {
									setOpenChat(true);
									setTicketPopup(false);
									dispatch(
										setActiveTicketId({ ticketNumber: data.ticketNumber, ticketTitle: data.ticketTitle })
									);
								}}>
								<div className={styles.Left}>
									<p>
										<ImTicket color="#FFB800" size={"12px"} /> Ticket No #{data.ticketNumber}
									</p>
									<h3>{data.ticketTitle}</h3>
									<span>
										{date.getDate()} {month[date.getMonth()]} {date.getFullYear()}
									</span>
								</div>
								<div className={styles.Right}>
									<p className={data.ticketStaus ? styles.Open : styles.Close}>
										{data.ticketStaus ? "Open" : "Close"}
									</p>
								</div>
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
};

export default TicketPopup;
