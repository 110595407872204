import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./assets/scss/App.css"

import { useEffect } from "react";
import { useDispatch } from "react-redux";
import Root from "./components/Root";
import { setInLocalStorage } from "./redux/slices/userSlice";

function App() {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(setInLocalStorage());
	}, []);

	return (
		<BrowserRouter>
			<ToastContainer
				position="top-center"
				autoClose={3000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss={false}
				draggable
				pauseOnHover={false}
				theme="dark"
			/>
			<Root />
		</BrowserRouter>
	);
}

export default App;
