import React, { useEffect, useState } from "react";
import { CirclesWithBar } from "react-loader-spinner";
import SA from "sweetalert";
import axios from "./hooks/axios";

const UserPostsPopup = ({ setPostShow, user }) => {
	const [totalPost, setTotalPost] = useState("");
	const [posts, setPosts] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [offset, setOffset] = useState(0);
	const [noPost, setNoPost] = useState(false);
	const [reload, setReload] = useState(false);

	useEffect(() => {
		if (reload) setPosts([]);
		axios
			.get(`/admin/getPost/${user._id}?offset=${offset}&type=`)
			.then(({ data }) => {
				console.log(data)
				if (!data.status) return SA("Something went wrong please try again!");
				setTotalPost(data.data.count.post);
				setPosts((pre) => [...pre, ...data.data.data]);
				setReload(false);
				if (data.data.data.length == 0) setNoPost(true);
				setIsLoading(false);
			})
			.catch((e) => console.log(e));
	}, [offset, reload]);

	const handelDelete = (post_id) => {
		axios
			.delete("admin/delete", { data: { _id: post_id } })
			.then(({ data }) => {
				if (data.error) return SA("Something went wrong please try again!");
				setReload(true);
			})
			.catch((e) => console.log(e));
	};

	const ImagePost = ({ post }) => {
		return (
			<div className="PostMainContainer">
				<div className="imageBox">
					<img src={post.media[0].file} alt="" />
				</div>
				<h6>{post.captions}</h6>
				<div className="contentBox">
					<p>likes = {post.likes_count}</p>
					<p>comments = {post.comment_counts}</p>
				</div>
				<button type="button" className="PostDeleteBtn" onClick={() => handelDelete(post._id)}>
					Delete
				</button>
			</div>
		);
	};
	const VideoPost = ({ post }) => {
		return (
			<div className="PostMainContainer">
				<div className="VideoBox">
					<video controls autoPlay={false}>
						<source src={post.media[0].file} />
					</video>
				</div>
				<h6>{post.captions}</h6>
				<div className="contentBox">
					<p>likes = {post.likes_count}</p>
					<p>comments = {post.comment_counts}</p>
				</div>
				<button type="button" className="PostDeleteBtn" onClick={() => handelDelete(post._id)}>
					Delete
				</button>
			</div>
		);
	};
	const AudioPost = ({ post }) => {
		return (
			<div className="PostMainContainer">
				<div className="AudioBox">
					<div className="waves">
						<div className="music-wave"></div>
						<div className="music-wave"></div>
						<div className="music-wave"></div>
						<div className="music-wave"></div>
						<div className="music-wave"></div>
						<div className="music-wave"></div>
						<div className="music-wave"></div>
						<div className="music-wave"></div>
						<div className="music-wave"></div>
						<div className="music-wave"></div>
					</div>
					<audio autoPlay={false} src={post.media[0].file} controls></audio>
				</div>
				<h6>{post.captions}</h6>
				<div className="contentBox">
					<p>likes = {post.likes_count}</p>
					<p>comments = {post.comment_counts}</p>
				</div>
				<button type="button" className="PostDeleteBtn" onClick={() => handelDelete(post._id)}>
					Delete
				</button>
			</div>
		);
	};
	const CaptionPost = ({ post }) => {
		return (
			<div className="PostMainContainer">
				<div className="CaptionBox">{post.captions}</div>
				<div className="contentBox">
					<p>likes = {post.likes_count}</p>
					<p>comments = {post.comment_counts}</p>
				</div>
				<button type="button" className="PostDeleteBtn" onClick={() => handelDelete(post._id)}>
					Delete
				</button>
			</div>
		);
	};

	const postCard = (post, index) => {
		if (post.media[0]) {
			let postType = post.media[0].type;
			if (postType == "image") return <ImagePost post={post} key={index} />;
			else if (postType == "video") return <VideoPost post={post} key={index} />;
			else if (postType == "audio") return <AudioPost post={post} key={index} />;
		} else {
			return <CaptionPost post={post} key={index} />;
		}
	};

	const handelOnScroll = (e) => {
		const bottom = e.target.scrollHeight - e.target.scrollTop - 151 === e.target.clientHeight - 150;
		if (bottom) {
			setOffset((prev) => prev + 10);
			console.log(bottom, "reach Bottom", offset);
		}
	};

	return (
		<div className="chat-popup MainBodyContainer UserPostsPopup">
			<div className="UserPostsPopupInner">
				<button className="close-popup" onClick={() => setPostShow(false)}>
					X
				</button>
				<h3>
					{user.full_name} total posts = {isLoading ? "Loading..." : totalPost}
				</h3>
				<div className="controls" onScroll={handelOnScroll}>
					{isLoading ? (
						<CirclesWithBar
							height="100"
							width="100"
							color="#DD3333"
							wrapperStyle={{
								width: "100%",
								height: "90%",
								displayL: "flex",
								alignItems: "center",
								justifyContent: "center",
							}}
							wrapperClass=""
							visible={true}
							outerCircleColor=""
							innerCircleColor=""
							barColor=""
							ariaLabel="circles-with-bar-loading"
						/>
					) : totalPost == 0 ? (
						<h1 style={{ marginTop: "10px" }}>No posts yeats</h1>
					) : null}
					{posts.map(postCard)}
					{noPost && <h5 style={{ textAlign: "center" }}>No More Post Available</h5>}
				</div>
			</div>
		</div>
	);
};

export default UserPostsPopup;
