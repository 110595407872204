import React, { useEffect, useRef, useState } from "react";
import { BiUpArrowAlt } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import styles from "../assets/scss/Support.module.scss";
import { io } from "socket.io-client";
import { setActiveTicketId, setActiveUser } from "../redux/slices/supportSlice";

const socket = io("https://musicbook.co.in/socket_connection");

const ChatPopup = ({ setOpenChat, setTicketPopup }) => {
	const { activeTicketId,activeTicketTitle, activeUser } = useSelector((state) => state.support);
	const { user } = useSelector((state) => state.user);
	const dispatch = useDispatch();
	const [text, setText] = useState("");
	const [chat, setChat] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const chatContainerRef = useRef();
	const room = activeTicketId;

	useEffect(() => {
		const domNode = chatContainerRef.current;
		domNode.scrollTop = domNode.scrollHeight;
	}, [chat]);

	useEffect(() => {
		socket.emit("joinRoom", room);
		setIsLoading(true);

		socket.on("chatHistory", (payload) => {
			setChat(payload);
			setIsLoading(false);
		});
	}, [activeTicketId]);

	useEffect(() => {
		socket.on(`message_${room}`, (payload) => {
			setChat((pre) => [...pre, payload]);
		});
	}, [socket]);

	const handelSend = () => {
		if (text === "") return;
		const data = {
			room,
			sender: user._id,
			message: text,
		};
		socket.emit("chatMessage", data);
		setChat((pre) => [
			...pre,
			{
				room,
				sender: user._id,
				message: text,
				updatedAt: new Date(),
			},
		]);
		setText("");
	};

	return (
		<div
			className={styles.PopupBackground}
			onClick={() => {
				setTicketPopup(false);
				setOpenChat(false);
				dispatch(setActiveUser(""));
				dispatch(setActiveTicketId({ ticketNumber: "", ticketTitle: "" }));
			}}>
			<div className={styles.ChatPopup} onClick={(e) => e.stopPropagation()}>
				<div className={styles.Top}>
					<div className={styles.Cont}>
						<img src={activeUser.pic} alt="" />
						<h2>{activeUser.full_name}</h2>
					</div>
					<h4>{activeTicketTitle}</h4>

					<button
						onClick={() => {
							setTicketPopup(true);
							setOpenChat(false);
							dispatch(setActiveTicketId({ ticketNumber: "", ticketTitle: "" }));
						}}>
						{"<"}
					</button>
				</div>

				<div className={styles.Bottom}>
					<div className={styles.ChatContainer} ref={chatContainerRef}>
						{isLoading && "Loading..."}
						{chat.map((data, index) => {
							const date = new Date(data.updatedAt);
							if (data.sender === user._id) {
								return (
									<div key={index} className={styles.MyChatContainer}>
										<div className={styles.MyChat}>
											<p>{data.message}</p>
											<span>
												{date.getHours() < 10 ? "0" + date.getHours() : date.getHours()}:
												{date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()}
											</span>
										</div>
									</div>
								);
							} else {
								return (
									<div key={index} className={styles.YourChatContainer}>
										<div className={styles.YourChat}>
											<p>{data.message}</p>
											<span>
												{date.getHours() < 10 ? "0" + date.getHours() : date.getHours()}:
												{date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()}
											</span>
										</div>
									</div>
								);
							}
						})}
					</div>

					<div className={styles.InputContainer}>
						<input
							type="text"
							placeholder="Type something"
							value={text}
							onChange={(e) => setText(e.target.value)}
							onKeyDown={(e) => {
								if (e.key === "Enter") handelSend();
							}}
						/>
						<div className={styles.Icon} onClick={handelSend}>
							<BiUpArrowAlt size={25} />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ChatPopup;
