import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import axios from "../../components/hooks/axios";

const initialState = {
	albumCount: 0,
	userAlbums: [],
	albumDetails: {},
	songDetails: [],
	isLoading: false,
	isAlbumDeleted: false,
	isSongDelete: false,
	isError: false,
	isSongLoading: false,
};

export const fetchUserAlbums = createAsyncThunk("fetchUserAlbums", async ({ id, offset }) => {
	const { data } = await axios.get(`album/get?offset=${offset}&user_id=${id}`);
	return data;
});

export const albumsDelete = createAsyncThunk("albumsDelete", async (id) => {
	const { data } = await axios.delete(`album/deleteAlbum/${id}`);
	return data;
});

export const fetchAlbumSongs = createAsyncThunk("fetchAlbumSongs", async ({ albumIndex, offset }) => {
	const { data } = await axios.get(`album/get-songs?offset=${offset}&album_id=${albumIndex}`);
	return { data, offset };
});



const albumSlice = createSlice({
	name: "albumSlice",
	initialState,
	reducers: {
		emptySong: (state) => {
			state.songDetails = [];
		},
	},
	extraReducers: {
		[fetchUserAlbums.pending]: (state) => {
			state.albumCount = 0;
			state.userAlbums = [];
			state.isLoading = true;
		},
		[fetchUserAlbums.fulfilled]: (state, { payload }) => {
			state.isLoading = false;
			if (payload.status) {
				state.albumCount = payload.data.length;
				state.userAlbums = payload.data;
			}
		},
		[albumsDelete.pending]: (state) => {
			state.albumCount = 0;
			state.isAlbumDeleted = false;
			state.isLoading = true;
		},
		[albumsDelete.fulfilled]: (state, { payload }) => {
			state.isLoading = false;
			if (payload.status) {
				state.isAlbumDeleted = true;
				toast.success("Album Successfully Deleted");
			} else {
				state.isError = true;
				toast.error(payload.message);
			}
		},
		[fetchAlbumSongs.pending]: (
			state,
			{
				meta: {
					arg: { offset },
				},
			}
		) => {
			if (offset === 0) {
				state.songDetails = [];
			}
			state.albumDetails = {};
			state.isSongDelete = false;
			state.isError = false;
			state.isSongLoading = true;
		},
		[fetchAlbumSongs.fulfilled]: (state, { payload: { data } }) => {
			state.isSongLoading = false;
			if (data.status) {
				state.albumDetails = data.albumDetails;
				state.songDetails.push(...data.data);
			} else {
				state.isError = true;
				toast.error(data.message);
			}
		},
	},
});

export const { emptySong } = albumSlice.actions;
export default albumSlice.reducer;
